.toastify_container {
  width: 375px;
  position: absolute;
  bottom: 45px;
  left: 15px;
}

.Toastify__toast {
  box-shadow: unset !important;
}
.toastify_root {
  padding: 0;
  min-height: auto;
  background: transparent;
}

.toastify_body {
  padding: 0;
  margin: 0;
}

/*MUI Tree css */
.MuiTreeItem-root ul li div {
  background-color: #f8f9f9 !important;
}
.MuiCollapse-wrapperInner ul li {
  padding-left: 30px;
  background-color: #f8f9f9 !important;
}

.slick-dots li.slick-active button {
  background-color: #ff8c1a;
}
.slick-dots li.slick-active {
  width: 10px !important;
  height: 10px !important;
  border: 1px solid #ff8c1a !important;
  padding: 1px;
  margin: 0 7px;
}
.MuiTreeItem-content {
  padding-left: 40px;
}
.pac-container.pac-logo {
  z-index: 999999;
  border-radius: 4px;
}
/* CK-editor */
.cke_bottom {
  display: none !important;
}
.cke_chrome {
  border: none !important;
}

/* input [type=number] side arrow */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}
#cke_1_contents {
  height: 100% !important;
}
.cke_inner {
  height: 183px !important;
}
.MuiInputBase-input {
  padding: 0rem 0.625rem !important;
  border-color: #4a4d68 !important;
  height: 36px;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #2680d9 !important;
}
#varificationCode div div {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
}
#varificationCode div div input {
  width: 40px;
  height: 36px;
  text-align: center;
  font-family: Lexend Deca !important;
  border: 1px solid #c3c5d5;
  border-radius: 2px;
  background-color: #f8f9f9;
}

#varificationCode div div input:focus-visible {
  outline: 1px solid #2680d9;
  border: none;
}
#varificationCode div div input:hover {
  border: 1px solid #4a4d68;
}
body::-webkit-scrollbar {
  display: none;
}
.noneIcon {
  display: none;
}

.MuiTreeItem-iconContainer:has(.noneIcon) {
  margin-right: -26px;
}
.contact-line {
  line-height: 20px;
}
.defalutFlag {
  height: 20px;
  width: 20px;
}
/* .noneIcon > .MuiTreeItem-iconContainer {
  background-color: red !important;
  width: 50% !important;
} */
