@media only screen and (max-width: 1200px) {
  .container {
    max-width: unset;
    padding: 0 50px;
  }
  .create-quote-request-right {
    width: 583px !important;
  }
  .parts-search-sales-item {
    min-height: 207px;
  }
  .footer-right {
    width: 56% !important;
  }
  .footer-ul {
    gap: 0 38px !important;
  }
  .every-order-right {
    height: 315px !important;
  }
  .supplier-card-root {
    height: 276px;
    width: 246px;
  }
  .hero {
    gap: 53px;
  }
  .pricing-plan {
    width: 347px;
  }
  .start-free-btn {
    width: 100% !important;
  }
  .commingsoon-button {
    width: 100% !important;
  }
  .seling-hero .heading-primary {
    padding-right: 52px;
  }
  .seling-hero .heading-primary {
    font-size: 35px;
    line-height: 45px;
  }
  .heading-cta {
    font-size: 26px;
    line-height: 38px;
  }
  .hero-img {
    height: 100%;
  }
  .cta-img-box {
    height: unset;
  }
  .about-hero {
    flex-wrap: nowrap;
    gap: 0 44px;
  }
  .industry {
    flex-wrap: nowrap;
    gap: 0 44px;
  }
  .performance-list-item {
    width: 32%;
  }
  .team {
    flex-wrap: nowrap;
    gap: 0 44px;
  }
  .heading-about-us {
    font-size: 40px;
    line-height: 52px;
  }
  .heading-industry {
    font-size: 31px;
  }
  .seling-hero {
    gap: 0 44px;
  }
}
@media only screen and (max-width: 1160px) {
  .how-it-work-item-inner {
    width: 315px !important;
    margin: 0 auto;
  }
  .create-quote-request-grid {
    display: flex;
    gap: 0 74px !important;
  }
  .create-quote-request-left h1 {
    font-size: 30px;
    line-height: 39px;
  }
  .parts-search-sales-item {
    /* width: 31.9% !important; */
    padding: 20px;
  }
  .every-order-right {
    height: 302px !important;
  }
  .supplier-card-root {
    height: 276px;
    width: 301px;
  }
  .profile-banner img {
    width: 100%;
  }
  .heading-primary {
    font-size: 32px;
    line-height: 44px;
  }
  .hero-text-box {
    gap: 12px 0;
  }
  .pricing-plan {
    width: 301px;
  }
  .seling-hero .heading-primary {
    padding: 0;
    font-size: 33px;
    line-height: 40px;
  }
  .call-to-action {
    gap: 35px;
  }
  .heading-cta {
    font-size: 23px;
    line-height: 34px;
  }
  .call-to-action-text .hero-heading-description {
    font-size: 15px;
    padding-bottom: 11px;
  }
  .pricing-plan-free {
    height: auto !important;
  }
  .pricing-plan {
    height: auto;
  }
  .pricing-plan-saver {
    height: auto !important;
  }
  .about-img {
    width: 496px;
    height: 358px;
  }
  .heading-about-us {
    font-size: 35px;
    line-height: 47px;
  }
  .about-text-box .hero-heading-description {
    font-size: 15px;
  }
  .about-text-box {
    height: unset;
    gap: 14px 0;
  }
  .heading-industry {
    font-size: 27px;
    line-height: 37px;
  }
  .performance-list-item {
    width: 31.9%;
  }
  .team-text-box {
    height: unset;
  }
}

@media only screen and (max-width: 1060px) {
  .p90 {
    padding: 70px 0;
  }
  .how-it-work-item-inner {
    width: 290px !important;
  }
  .findGreatOverlay {
    bottom: 140px;
  }
  .create-quote-request-grid {
    flex-direction: column;
    gap: 40px 0 !important;
    align-items: unset;
  }
  .create-quote-request-left {
    width: 100% !important;
    margin-top: unset;
    text-align: center !important;
    max-width: 700px;
    margin: 0 auto;
  }
  .create-quote-request-right {
    width: 100% !important;
  }
  .create-quote-request-left h1 {
    text-align: center;
  }
  .create-quote-request-left .sign-seeAll-btn {
    justify-content: center;
  }
  .parts-search-sales-item {
    /* width: 31.8% !important; */
    padding: 15px;
  }
  .every-order-grid {
    gap: 0 60px !important;
  }
  .every-order-right {
    height: 343px !important;
  }
  .every-order-right {
    width: 44% !important;
  }
  .footer-right {
    width: 61% !important;
  }
  .title {
    font-size: 30px;
    line-height: 39px;
  }
  .request-deail-right-inner h1 {
    font-size: 24px;
  }
  .supplier-card-root {
    height: 276px;
    width: 289px;
  }
  .hero {
    gap: 35px;
  }
  .pricing-plan {
    width: 289px;
  }
  .pricing-plan {
    height: auto;
  }
  .seling-hero .heading-primary {
    padding: 0;
    font-size: 30px;
    line-height: 40px;
  }
  .performance-list-item {
    width: 31.8%;
  }
}
@media only screen and (max-width: 1023px) {
  .supplier-card-root {
    height: 276px;
    width: 280px;
  }
}

@media only screen and (max-width: 1000px) {
  .p90 {
    padding: 60px 0;
  }
  .container {
    padding: 0 40px;
  }
  .how-it-work-item-inner {
    width: 285px !important;
  }
  .findGreatOverlay {
    bottom: 130px;
  }
  .every-order-left-inner p {
    padding-bottom: 22px;
  }
  .every-order-left-inner h3 {
    padding-bottom: 15px;
  }
  .every-order-grid {
    gap: 0 40px !important;
  }
  .every-order-right {
    width: 46% !important;
  }
  .every-order-right {
    height: 323px !important;
  }
  .footer-ul {
    gap: 0 22px !important;
  }
  .quoterequest-inner {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .request-deail-right-inner h1 {
    font-size: 22px;
  }
  .banner-img-content h1 {
    font-size: 36px;
    line-height: 45px;
  }
  .brands-slider-section {
    /* margin-bottom: 30px; */
  }
  .supplier-card-root {
    height: 276px;
    width: 285px;
  }
  .detailpage-body {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .heading-primary {
    font-size: 29px;
    line-height: 41px;
  }
  .hero-heading-description {
    font-size: 14px;
    line-height: 22px;
  }
  .pricing-plan {
    width: 285px;
  }
  .heading-about-us {
    font-size: 32px;
    line-height: 41px;
  }
  .industry-img-box {
    width: 548px;
    height: 304px;
  }
  .seling-hero {
    gap: 0 17px;
  }
  .request-detail-message-inner p {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
  }
}

@media only screen and (max-width: 990px) {
  .seling-hero {
    flex-direction: column-reverse;
    gap: 35px 0;
  }
  .burger {
    display: block;
  }
  .industry-grid-item {
    /* width: unset !important; */
  }
  .nav-menu {
    position: absolute;
    flex-direction: column;
    width: 40% !important;
    left: 0;
    right: 0;
    z-index: 99;
    background: #36384c;
    top: 80px;
    padding: 50px;
    height: 700px !important;
    transform: translateX(-100%);
    transition: 0.2s ease;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  .nav-menu-open {
    transform: translateX(0);
    position: absolute;
    flex-direction: column;
    width: 40% !important;
    left: 0;
    right: 0;
    z-index: 99;
    background: #36384c;
    top: 80px;
    padding: 50px;
    height: 700px !important;
    transition: 0.2s ease;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    /* transform: translateX(-100%); */
  }
  .nav-menu-open span a span {
    color: #fff;
  }
  .page-logo {
    /* order: 2; */
    width: 72% !important;
  }

  .p90 {
    padding: 45px 0;
  }
  .how-it-work-grid {
    flex-flow: wrap;
  }
  .how-it-work-item {
    width: 48% !important;
  }
  .how-it-work-item-inner {
    width: 100% !important;
  }
  .how-it-work-grid {
    gap: 20px !important;
  }
  .trusted-supplier-grid {
    gap: 18px;
  }
  .findGreatOverlay {
    bottom: 115px;
  }
  .parts-title {
    font-size: 25px;
  }
  .title {
    font-size: 25px;
  }
  .create-quote-request-left h1 {
    font-size: 25px;
  }
  .parts-search-sales-item {
    /* width: 48.5% !important; */
    padding: 15px;
  }
  .every-order-grid {
    flex-direction: column-reverse;
  }
  .every-order-left {
    width: 100% !important;
  }
  .every-order-right {
    width: 100% !important;
    margin-bottom: 35px;
  }
  .every-order-left-inner h1 {
    padding-bottom: 3px;
    font-size: 24px;
  }
  .every-order-right {
    height: 410px !important;
  }
  .footer-ul {
    gap: 0 10px !important;
  }
  .quoterequest-list-main .create-quote-request-list {
    padding: 0 40px;
  }
  .request-detail-grid {
    flex-direction: column;
    gap: 35px 0 !important;
  }
  .request-detail-left {
    width: 40% !important;
    height: 294px !important;
  }
  .request-detail-right {
    width: 100% !important;
  }
  .request-detail-body {
    padding: 50px 0;
  }
  .burger {
    height: unset !important;
    width: unset !important;
    background-color: unset !important;
    display: flex;
    align-items: center;
  }
  .industry-grid-img {
    width: 165px !important;
    height: 115px !important;
  }
  .industry-grid {
    gap: 0 15px !important;
  }
  .industry-grid-inner {
    padding: 0 40px;
    /* max-width: 880px; */
  }
  .industry-grid-item {
    padding: 10px 10px 20px;
  }
  .banner-img img {
    /* height: 423px; */
  }
  .parts-search-sales-item {
    min-height: 184px;
  }
  .supplier-card-root {
    height: 276px;
    width: 48.5%;
  }
  .supplier-body {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .detailPageHeader h1 {
    font-size: 25px;
    line-height: 33px;
  }
  .hero {
    display: flex;
    flex-direction: column-reverse;
  }
  .hero-text-box {
    width: 100%;
  }
  .hero-img-box {
    width: 100%;
  }
  .pricing-plan {
    width: 48%;
  }
  .heading-secondary {
    font-size: 30px;
    line-height: 37px;
  }
  .pricing-plan-saver {
    height: auto !important;
  }
  .seling-hero .heading-primary {
    padding: 0;
    font-size: 27px;
    line-height: 33px;
  }
  .verification-list {
    padding: 0;
  }
  .verification-list-item {
    width: 48%;
  }
  .call-to-action {
    flex-direction: column-reverse;
    align-items: unset;
  }
  .call-to-action {
    gap: 11px;
  }
  .about-hero {
    flex-direction: column-reverse;
    gap: 35px 0;
  }
  .about-text-box {
    width: 100%;
  }
  .heading-about-us {
    font-size: 28px;
  }
  .industry {
    flex-direction: column-reverse;
    gap: 15px 0;
  }
  .industry-img-box {
    height: unset;
  }
  .industry-text-box {
    width: 100%;
  }
  .performance-list-item {
    width: 48%;
  }
  .team {
    flex-direction: column;
    gap: 35px 0;
  }
  .team-text-box {
    width: 100%;
  }
  .footer-right {
    width: 82% !important;
  }
  .request-detail-message-inner p {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  .industry-part-title h2 {
    font-size: 26px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .page-logo {
    width: 65% !important;
  }
  .industry-grid-item {
    width: unset !important;
  }
  .container {
    padding: 0 20px;
  }
  .trusted-supplier-grid {
    gap: 10px;
  }
  .trusted-supplier-item {
    width: 23.6% !important;
    height: 166px !important;
    padding: 20px 6px;
  }
  .create-quote-request-right-tabs button {
    width: 155px !important;
  }
  .parts-search-sales-item {
    /* width: 47.5% !important; */
    padding: 15px;
  }
  .every-order-right {
    height: unset !important;
  }
  .footer-grid {
    flex-direction: column;
  }
  .footer-ul {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .footer-right {
    width: 100% !important;
  }
  .quoterequest-list-main .create-quote-request-list {
    padding: 0 20px;
  }
  .quoterequest-title h3 {
    font-size: 24px;
  }
  .quoterequest-inner {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .request-detail-left {
    width: 40% !important;
    height: 233px !important;
  }
  .banner-mobile {
    display: block;
  }
  .banner-desktop {
    display: none;
  }
  .banner-img-content {
    padding-top: 40px;
  }
  .banner-img-content h1 {
    font-size: 29px;
    line-height: 36px;
  }
  .industry-part {
    /* position: unset; */
  }
  .industry-part {
    bottom: -347px;
    width: 100% !important;
  }

  .industry-grid {
    flex-direction: column;
    gap: 20px 0 !important;
  }
  .industry-grid-item {
    display: flex;
    background-color: #fff;
    height: unset !important;
  }
  .industry-grid-inner {
    padding: 20px;
    max-width: 400px;
  }
  .industry-part {
    /* margin-top: -180px; */
  }
  .industry-grid-inner {
    background-color: #fff;
  }
  .brands-slider-section {
    /* margin-top: 62%; */
  }
  .time-upload {
    display: none;
  }
  .request-detail-body .start-quote-btn {
    width: 111px !important ;
    height: 36px !important;
  }
  .supplier-card-root {
    height: 276px;
    width: 48%;
  }
  .detailpage-body {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .visitWebsiteRoot {
    flex-direction: column;
    gap: 10px 0 !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  .detailPageHeader h1 {
    font-size: 22px;
    line-height: 30px;
  }
  .verification {
    margin: 0;
  }
  .call-to-action-text {
    display: unset;
    width: unset;
  }
  .heading-about-us {
    font-size: 22px;
    line-height: 30px;
  }
  .about-hero {
    gap: 15px 0;
  }
  .about-text-box {
    height: unset;
    gap: 10px 0;
  }
  .heading-industry {
    font-size: 22px;
    line-height: 30px;
  }
  .heading-performance {
    font-size: 22px;
    line-height: 30px;
  }
  .team-img-box {
    width: 100%;
    height: unset;
  }
  .seling-hero {
    gap: 15px 0;
  }
  .seling-hero .hero-img {
    width: 100%;
    height: unset;
  }
  .seling-hero .hero-img-box {
    width: 100%;
    height: unset;
  }
  .section-cta .cta-img-box {
    height: 100%;
    width: 100%;
  }
  .section-cta .cta-img-box .hero-img {
    height: 100%;
    width: 100%;
  }
  .searchfield-root {
    max-width: 420px;
  }
  .industry-part-title {
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 425px) {
  .section-cta .button-sign-up {
    width: 132px !important;
  }
  .title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .how-it-work-item {
    width: 100% !important;
  }
  .p90 {
    padding: 35px 0;
  }
  .trusted-supplier-title .veryfied {
    margin-bottom: 20px;
    line-height: 26px;
  }
  .trusted-supplier-title img {
    display: none;
  }
  .trusted-supplier-item {
    width: 48% !important;
  }
  .findGreatOverlay {
    bottom: 105px;
  }
  .create-quote-request-left h1 {
    font-size: 21px;
  }
  .page-logo {
    width: 53% !important;
  }
  .parts-search-sales-item {
    /* width: 100% !important; */
  }
  .parts-search-sales-item {
    padding: 15px 80px 15px 15px;
  }
  .frequently-ask-question-accordian .MuiAccordionSummary-content {
    margin: 20px 0;
  }
  .every-order-left-inner h1 {
    padding-bottom: 10px;
    font-size: 23px;
    line-height: 30px;
  }
  .parts-title {
    font-size: 22px;
    line-height: 28px;
  }
  .footer-ul {
    /* flex-direction: column; */
    flex-flow: wrap !important;
    gap: 20px 40px !important;
    justify-content: flex-start !important;
    margin: 0 !important;
  }
  .footer-right {
    display: flex;
    flex-direction: column-reverse;
  }
  .social-media-flex {
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .footer-ul span {
    /* margin-bottom: 10px; */
  }
  .copyright-sec {
    flex-direction: column;
    gap: 10px 0 !important;
  }
  .nav-menu {
    transform: translateX(-101%);
  }
  .nav-menu-open {
    width: 75% !important;
  }
  .quoterequest-title h3 {
    font-size: 19px;
  }
  .footer-grid {
    padding-bottom: 15px;
  }
  .request-detail-left {
    width: 100% !important;
    height: 290px !important;
  }
  .request-detail-grid {
    flex-direction: column;
    gap: 25px 0 !important;
  }
  .request-deail-right-inner h1 {
    font-size: 19px;
  }
  .request-detail-body {
    padding: 40px 0;
  }
  .start-quote-btn {
    width: 109px !important;
    height: 36px !important;
    margin-top: 20px;
  }
  .banner-img-content h1 {
    font-size: 22px;
    line-height: 30px;
  }
  .banner-img-content p {
    font-size: 12px;
    line-height: 18px;
    /* padding: 0 20px; */
  }
  .industry-part {
    bottom: -296px;
    width: 100% !important;
  }
  .industry-grid-img {
    width: 80px !important;
    height: 70px !important;
  }
  .industry-grid-img img {
    width: 80px;
    height: 70px;
  }
  .industry-grid-img {
    margin: unset !important;
  }
  .industry-grid-item p {
    font-size: 14px;
    margin-top: 0;
  }
  .start-quote-btn {
    width: 111px !important;
    height: 36px !important;
  }
  .industry-grid-inner {
    max-width: unset;
    margin: 0 20px;
  }
  .industry-grid-img-con {
    text-align: start !important;
  }
  .industry-grid-item {
    padding: 10px;
  }
  .banner-img img {
    height: 378px;
  }
  .industry-grid-inner {
    max-width: unset;
    padding: 15px;
  }
  .industry-part {
    bottom: -166px;
    width: 100% !important;
  }
  .industry-grid {
    gap: 15px 0 !important;
  }
  .banner-img-content {
    padding: 0 30px;
    padding-top: 40px;
  }
  .brands-slider-section {
    /* margin-top: 48%; */
  }
  .header-root {
    height: 48px !important;
  }
  .header-root button img {
    width: 40px;
    height: 40px;
  }
  .industry-grid-item {
    gap: 0 20px !important;
  }
  .brands-slider-section {
    /* margin-bottom: 40px; */
  }
  .brands-slider-section {
    padding: 24px 0;
  }
  .industry-part-title h2 {
    font-size: 20px;
    line-height: 27px;
  }
  .p90 {
    padding: 40px 0;
  }
  .title {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .how-it-works-inner button {
    font-size: 14px !important;
    /* width: 160px !important; */
    height: 36px !important;
  }
  .how-it-work-grid {
    margin-top: 25px;
  }
  .how-it-work-item-inner {
    gap: 15px 0 !important;
  }
  .trusted-supplier-title .veryfied {
    line-height: 30px;
    margin-bottom: 10px;
  }
  .trusted-supplier-title p {
    font-size: 12px;
    line-height: 18px;
  }
  .trusted-supplier-grid {
    margin-top: 20px;
  }
  .trusted-supplier-grid {
    gap: 20px;
  }
  .trusted-supplier-item {
    width: 47% !important;
    padding: 10px 15px 15px;
    height: 155px !important;
  }
  .trusted-supplier-grid .trusted-supplier-item:nth-child(7) {
    display: none;
  }
  .trusted-supplier-grid .trusted-supplier-item:nth-child(8) {
    display: none;
  }
  .findGreatOverlay p {
    font-size: 14px;
    line-height: 20px;
  }
  .signBtn {
    width: 119px !important;
    height: 36px;
  }
  .seeAllBtn {
    width: 145px !important;
    height: 36px;
  }
  .findGreatOverlay {
    bottom: 91px;
  }
  .create-quote-request-left h1 {
    font-size: 22px;
    text-align: start;
    line-height: 30px;
  }
  .create-quote-request-left p {
    font-size: 12px;
    text-align: start;
    line-height: 18px;
  }
  .startBtn {
    width: 111px !important;
    height: 36px;
  }
  .seeAllRqBtn {
    width: 137px !important;
    height: 36px;
  }
  .create-quote-request-left .seeAllRqBtn {
    width: 145px !important;
  }

  .sign-seeAll-btn {
    margin-top: 15px;
  }
  .create-quote-request-left .sign-seeAll-btn {
    justify-content: flex-start;
  }
  .create-quote-request-right-tabs button {
    height: 36px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 0 10px !important;
    width: auto !important;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
  }
  .create-quote-request-right-tabs .makeStyles-btmLandingTabs-2 div div {
    overflow-x: scroll;

    white-space: nowrap;
    justify-content: flex-start;
  }
  .create-quote-request-right-tabs .makeStyles-btmLandingTabs-2 div div::-webkit-scrollbar {
    display: none;
  }
  .create-quote-request-grid {
    gap: 30px 0 !important;
  }
  .card-left-img {
    display: none !important;
  }
  .create-quote-request-list-card {
    padding: 15px;
  }
  .brand-paragraph {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .parts-title {
    font-size: 22px;
    margin-bottom: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .parts-search-sales-item {
    padding: 15px;
    min-height: unset;
    margin: 0 10px;
  }
  .parts-search-sales-inner h1 {
    margin-bottom: 20px;
  }
  .frequently-ask-question-inner h3 {
    font-size: 14px;
    line-height: 20px;
  }
  .frequently-ask-question-accordian p {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 15px;
  }
  .every-order-right {
    margin-bottom: 20px;
  }
  .every-order-left-inner h1 {
    font-size: 22px;
  }
  .every-order-left-inner h3 {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 10px;
  }
  .every-order-left-inner p {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 20px;
  }
  .every-order-left-inner button {
    width: 210px;
    height: 36px;
  }
  .footer-grid {
    padding-bottom: 30px;
  }
  .copyright-sec {
    flex-direction: column-reverse;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    gap: 20px 0 !important;
  }
  .nav-menu-open {
    top: 48px;
  }
  .nav-menu {
    top: 48px;
  }
  .desktop-img {
    display: none;
  }
  .mobile-img {
    display: block;
  }
  .supplier-search-inner h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .supplier-body {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .search-filed input {
    width: 192px;
  }
  .supplier-card-root {
    width: 100%;
  }
  .username {
    flex-direction: column;
    gap: 10px 0 !important;
  }
  .parts-chip-and-social {
    flex-direction: column;
    gap: 10px 0 !important;
  }
  .parts-chip-and-social div {
    flex-flow: wrap;
    justify-content: flex-start;
  }
  .profileMain .makeStyles-profileInner-4 {
    display: flex;
    padding: 30px 15px;
    flex-direction: column;
  }
  .speciliationFlex {
    flex-direction: column;
    gap: 20px 0 !important;
  }
  .hero-img {
    width: 100%;
    height: unset;
  }
  .hero-img-box {
    height: unset;
  }
  .heading-primary {
    font-size: 22px;
    line-height: 30px;
  }
  .hero-text-box {
    gap: 8px 0;
  }
  .hero-description {
    font-size: 13px;
  }
  .heading-secondary {
    font-size: 22px;
    line-height: 30px;
  }
  .heading-text {
    font-size: 13px;
  }
  .pricing-plan {
    width: 100%;
  }
  .pricing-plan-free .list {
    height: unset;
  }
  .pricing-plan-free {
    height: unset !important;
  }
  .cta-img-box {
    width: 100%;
  }
  .seling-hero .heading-primary {
    padding: 0;
    font-size: 22px;
    line-height: 30px;
  }
  .verification-list-item {
    width: 100%;
  }
  .verification-list {
    margin-top: 40px;
    margin-bottom: 0;
  }
  .heading-cta {
    font-size: 22px;
    line-height: 30px;
  }
  .page-logo button {
    width: unset;
    height: unset;
  }
  .about-img {
    width: 100%;
    height: unset;
  }
  .industry-img-box {
    width: 100%;
  }
  .team-img-box img {
    width: 100%;
  }
  .about-img img {
    width: 100%;
    height: unset;
  }
  .about-img-box {
    width: 100%;
    height: unset;
  }
  .performance-list-item {
    width: 100%;
  }
  .team {
    flex-direction: column;
    gap: 22px 0;
  }
  .everyOrderBtn {
    width: 210px;
    height: 36px;
  }
  .start-free-btn {
    height: 36px !important;
  }
  .commingsoon-button {
    height: 36px !important;
  }
  .button-sign-up {
    height: 36px !important;
    width: 112px !important;
  }
  .still-question {
    padding: 25px;
  }
  .create-quote-request-right-tabs .makeStyles-blueLinks-4 {
    color: #2680d9;
    width: unset !important;
    height: unset !important;
    padding: unset !important;
    z-index: 99999;
    background: transparent !important;
    min-height: unset !important;
    line-height: 1 !important;
    border-color: #2680d9;
    border-bottom: 1px solid;
    border-radius: unset;
  }
}
@media only screen and (max-width: 395px) {
  .page-logo {
    width: 50% !important;
  }
  .title {
    line-height: 31px;
  }
  .create-quote-request-left h1 {
    font-size: 21px;
    line-height: 29px;
  }
  .pagination-bottom li button {
    min-width: 31px !important;
    height: 31px !important;
  }
  .quoterequest-title h3 {
    font-size: 16px;
  }
  .quoterequest-inner #react-select-2-input {
    width: 150px !important;
  }
  .select-drop-down {
    width: 150px !important;
  }
  .request-deail-right-inner h1 {
    font-size: 19px;
    line-height: 28px;
    padding-bottom: 9px;
  }
  .industry-list li {
    font-size: 10px;
  }
  .industry-part-title {
    padding: 0 20px;
    padding-bottom: 25px;
  }
}
