.container {
  max-width: 1180px;
  margin: 0 auto;
}
.p90 {
  padding: 90px 0;
}
.title {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: #13141b;
  margin-bottom: 20px;
  text-align: center;
}
.how-it-work-grid {
  display: flex;
  gap: 0 20px !important;
}
.how-it-work-item {
  width: 33.33% !important;
}
.how-it-work-item-inner {
  width: 340px !important;
  margin: 0 auto;
}
.how-it-work-item-inner img {
  width: 100%;
}
.commoncolor {
  color: #13141b;
}
.p6 {
  padding-bottom: 6px;
}
.commonflex {
  display: flex;
}
.how-it-work-grid {
  margin-top: 30px;
}
.trusted-supplier-sec {
  background: #f8f8fa;
}
.veryfied {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.trusted-supplier-title .veryfied {
  margin-bottom: 10px;
}
.trusted-supplier-title p {
  max-width: 752px;
  margin: 0 auto;
  text-align: center;
}
.trusted-supplier-title p span {
  border-bottom: 1px solid #4a4d68;
}
.trusted-supplier-grid {
  max-width: 780px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  margin-top: 30px;
  flex-flow: wrap;
}
.trusted-supplier-item {
  width: 23% !important;
  height: 170px !important;
  background-color: #fff;
  padding: 20px 15px;

  border: 1px solid #f0f0f4;
}
.trusted-supplier-item-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100% !important;
  text-align: center !important;
}
.seeAllBtn {
  width: 175px !important;
  height: 50px;
  border: 1px solid #9799b5;
  color: #4a4d68 !important;
  background-color: transparent;
}
.seeAllBtn:hover {
  background-color: #d3d5e5 !important;
}
.everyOrderBtn:hover {
  background-color: #d3d5e5 !important;
}
.signBtn {
  width: 149px !important;
  height: 50px;
}
.findGreatOverlay p {
  text-align: center;
  padding-bottom: 20px;
}
.findGreatOverlay {
  background: linear-gradient(180deg, rgba(248, 248, 250, 0.12) 0%, #f8f8fa 71.95%);
}
.trusted-supplier-inner {
  position: relative;
}
.findGreatOverlay {
  position: absolute;
  bottom: 158px;
  height: 170px !important;
  display: flex;
  justify-content: center;
  align-items: flex-end !important;
  left: 0;
  right: 0;
}
.sign-seeAll-btn {
  margin-top: 20px;
}
.startBtn {
  width: 141px !important;
  height: 50px;
}
.seeAllRqBtn {
  width: 167px !important;
  height: 50px;
  border: 1px solid #9799b5;
  color: #4a4d68 !important;
  background-color: transparent;
}
.seeAllRqBtn:hover {
  background-color: #d3d5e5 !important;
}
.create-quote-request-left h1 {
  margin-bottom: 10px;
}
.create-quote-request-grid {
  display: flex;
  gap: 0 120px !important;
}
.create-quote-request-right {
  width: 680px !important;
}
.create-quote-request-left {
  width: 380px !important;
  text-align: start !important;
  margin-top: 65px;
}
.create-quote-request-left h1 {
  text-align: start;
}
.create-quote-request-left .sign-seeAll-btn {
  justify-content: flex-start;
}
.create-quote-request-list-card {
  padding: 20px;
  border: 1px solid #f0f0f4;
  border-radius: 4px;
}
.create-quote-request-list-card:hover {
  cursor: pointer;
  box-shadow: 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04);
}
.card-left-img img {
  width: 90px;
  height: 67px;
  object-fit: cover;
}
.create-quote-request-list {
  display: flex;
  flex-direction: column;
  gap: 20px 0 !important;
  margin-top: 20px;
}
.card-right {
  display: flex;
  flex-direction: column;
  gap: 5px 0 !important;
}
.card-title h3 {
  padding-bottom: 4px;
}
.card-right p img {
  margin-left: 5px;
}
.flag-content {
  display: flex;
  align-items: center;
}
.create-quote-request-list {
  max-height: 470px;
  overflow-y: scroll;
}
.create-quote-request-list::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #f0f0f4;
}
.create-quote-request-list::-webkit-scrollbar-thumb {
  width: 4px;
  height: 60px;
  border-radius: 100px;
  background-color: #c3c5d5;
}
.parts-search-sales-sec {
  background: #36384c;
}
.parts-title {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
}
.parts-search-sales-grid {
  display: flex;
  gap: 20px !important;
  flex-flow: wrap;
  margin-top: 30px;
}
.parts-search-sales-item {
  /* width: 32.2% !important; */
  background: #43465e;
  border-radius: 4px;
  padding: 25px 18px;
  min-height: 184px;
  margin: 0 10px;
}
.parts-search-sales-item-inner h3 {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.parts-search-sales-flex {
  display: flex;
  align-items: flex-start;
  gap: 0 10px !important;
  margin-top: 20px;
}
.frequently-ask-question-accordian .MuiPaper-root {
  border-left: unset;
  border-right: unset;
  border-top: unset;
  box-shadow: unset;
  border-bottom: 1px solid #f0f0f4;
}
.frequently-ask-question-accordian .MuiPaper-root::before {
  content: unset;
}
.frequently-ask-question-accordian .MuiAccordionSummary-content {
  margin: 23px 0;
}
.frequently-ask-question-accordian .MuiButtonBase-root {
  min-height: unset;
  padding: 0;
}
.frequently-ask-question-accordian p {
  padding-bottom: 20px;
}
.frequently-ask-question-accordian .MuiAccordionDetails-root {
  padding: 0;
}
.frequently-ask-question-inner {
  max-width: 780px;
  margin: 0 auto;
}
.every-order-grid {
  display: flex;
  gap: 0 110px !important;
  align-items: center;
}
.every-order-sec {
  background: #f8f8fa;
}
.every-order-left-inner h1 {
  text-align: start;
  padding-bottom: 10px;
  margin-bottom: 0;
}
.every-order-left-inner h3 {
  padding-bottom: 20px;
}
.every-order-left-inner p {
  padding-bottom: 30px;
}
.every-order-left-inner button {
  width: 240px;
  height: 50px;
}
.every-order-left {
  width: 50% !important;
}
.every-order-right {
  width: 41% !important;
}
.every-order-right {
  height: 338px !important;
  box-shadow: 0px 16px 14px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}
.every-order-right img {
  height: 100%;
  width: 100%;
}
.footer-main {
  background: #36384c;
}
.footer-grid {
  display: flex;
  /* align-items: center; */
  padding-bottom: 6px;
  border-bottom: 1px solid #4a4d68;
  justify-content: space-between;
}
.adddresLine {
  padding-bottom: 4px;
  color: #fff;
}
.footer-left p {
  line-height: 18px;
}
.footer-ul {
  margin-top: 14px !important;
  display: flex;
  justify-content: flex-end;
  gap: 0 40px !important;
}
.footer-right {
  width: 69% !important;
}
.social-media-flex {
  display: flex;
  height: 80% !important;
  justify-content: flex-end;
  align-items: flex-end !important;
  gap: 0 8px !important;
}
.austinflag {
  padding-right: 20px;
  border-right: 1px solid #4a4d68;
}
.torontoFlag {
  padding-left: 20px;
}
.footer-inner {
  padding: 40px 0;
}
.copyright-sec {
  margin-top: 30px;
}
.burger {
  display: none;
}
.quoterequest-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quoterequest-title h3 {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #13141b;
  line-height: 36px;
}
.quoterequest-list-main {
  max-width: 780px;
  margin: 0 auto;
}
.quoterequest-list-main .create-quote-request-list {
  margin-top: 30px;
  margin-bottom: 50px;
}
.quoterequest-inner {
  padding-top: 60px;
  padding-bottom: 100px;
}
.pagination-bottom ul {
  justify-content: center;
}
.next-prev-bottoms {
  display: flex;
  justify-content: space-between;
  max-width: 780px;
  margin: 0 auto;
}
.quoterequest-list-main .create-quote-request-list {
  max-height: 594px;
}
.footer-grid {
  padding-bottom: 30px;
}
.footer img {
  margin-bottom: 4px;
}
.select-drop-down {
  width: 200px !important;
}
.request-detail-grid {
  display: flex;
  justify-content: space-between;
}
.request-detail-left {
  /* width: 42.4% !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0f0f4;
  border-radius: 4px;
  height: 360px !important;
}
.request-detail-right {
  width: 50.9% !important;
}
.request-detail-body {
  /* padding: 70px 0; */
  padding-top: 30px !important;
  padding-bottom: 70px;
}
.request-detail-body {
  border-top: 1px solid #f0f0f4;
}
.request-detail-grid {
  margin-top: 20px;
}
.request-deail-right-inner h1 {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #13141b;
}
.product-detail p a {
  color: #2680d9 !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  font-family: Lexend Deca !important;
}
.product-detail-grid {
  display: flex;
  gap: 0 20px !important;
}
.product-detail-grid p {
  width: 200px;
}
.product-detail {
  display: flex;
  gap: 10px 0 !important;
  margin-top: 30px;
  flex-direction: column;
  margin-bottom: 20px;
}
.request-detail-body .start-quote-btn {
  width: 200px !important;
  height: 50px !important;
  margin-top: 30px !important;
  border: unset !important;
  margin-right: auto !important;
  margin-left: unset !important;
}
.start-quote-btn button {
  height: 100% !important;
}
.banner-img img {
  width: 100%;
}
.banner-section {
  position: relative;
}
.banner-img-content {
  position: absolute;
  top: 0;
}
.banner-img-content h1 {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 56px;
  text-align: center;
  padding: 10px 0;
  color: #ffffff;
}
.banner-img-content p {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* padding: 0 94px; */
  color: #f0f0f4;
}
.banner-img-content {
  text-align: center !important;
  max-width: 852px;
  margin: 0 auto;
  width: 100% !important;
  left: 0;
  right: 0;
}
.banner-img-content {
  /* padding-top: 90px; */
  display: flex;
  flex-direction: column;
  height: 100% !important;
  justify-content: center;
}
.industry-grid {
  display: flex;
  gap: 0 20px !important;
  justify-content: center;
}
.start-quote-btn {
  width: 108px !important;
  height: 36px !important;
  background-color: transparent !important;
  border: 1px solid #9799b5 !important;
  border-radius: 4px;
  margin: 0 auto !important;
  margin-top: 15px !important;
  color: #4a4d68 !important;
}
.industry-grid-item {
  border: 1px solid #f0f0f4;
  border-radius: 4px;
  text-align: center !important;
  padding: 15px 20px 20px;
  width: 32% !important;
  height: 238px !important;
  box-shadow: 0px 12px 16px rgba(21, 22, 30, 0.06), 0px 4px 6px rgba(21, 22, 30, 0.04);
}
.industry-grid-item p {
  margin-top: 15px;
}
.industry-grid-img {
  width: 160px !important;
  height: 106px !important;
  margin: 0 auto;
}

.industry-grid-img img {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.industry-grid-item button:hover {
  background-color: #f0f0f4 !important;
}

.industry-grid-inner {
  /* box-shadow: 0px 16px 14px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04); */
  /* border-radius: 4px; */
  /* padding: 30px; */
  max-width: 880px;
  margin: 0 auto;
  background-color: #fff;
}
.industry-part {
  /* position: absolute; */
  /* bottom: -140px; */
  /* width: 100%; */
  /* left: 0; */
  /* right: 0; */
}
.banner-img img {
  width: 100%;
  height: 378px;
  object-fit: cover;
}
.brands-slider-section {
  /* margin-top: 168px; */
  /* margin-bottom: 50px; */
  padding: 40px 0;
  background-color: #f8f8fa;
}

.parts-search-sales-inner h1 {
  margin-bottom: 42px;
}
.banner-mobile {
  display: none;
}
.banner-desktop {
  display: block;
}
.header-root {
  align-items: center !important;
  padding: 0;
  height: 80px !important;
}
.landing-header {
  background-color: #fff;
}
.brand-paragraph {
  color: #4a4d68;
}
.desktop-img {
  display: block;
}
.mobile-img {
  display: none;
}
.time-upload {
  color: #4a4d68;
}
.quoterequest-inner {
  border-top: 1px solid #f0f0f4;
}
.supplier-body {
  padding-top: 60px;
  padding-bottom: 100px;
}

.search-filed input {
  width: 280px;
}
.supplier-search-inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.supplier-search-inner h3 {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #13141b;
}
.supplier-grid {
  display: flex;
  flex-flow: wrap;
  gap: 20px !important;
}
.supplier-body {
  border-top: 1px solid #f0f0f4;
}
.supplier-card-root {
  height: 276px;
  width: 280px;
  border-radius: 0px;
  transition: 'boxShadow 2s ease-out 100ms';
  border: 1px solid #f0f0f4;
  position: relative;
  background-color: #fff;
}

.supplier-card-root:hover {
  box-shadow: 0px 16px 14px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.supplier-grid {
  margin-bottom: 50px;
}
.detailPageHeader {
  display: flex;
  /* padding: 30px; */
  gap: 0 10px;
  flex-direction: column;
}
.detailpage-body {
  border-top: 1px solid #f0f0f4;
}
.detailPageHeader h1 {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #13141b;
  padding-top: 10px;
}
.detailPageHeader p {
  display: flex;
  gap: 0 3px;
}
.detailPageHeader div {
  cursor: pointer;
}

.detailpage-body {
  padding-top: 60px;
  padding-bottom: 80px;
}
.profileMain {
  max-width: 980px;
  margin: 0 auto;
  margin-top: 30px;
  border: 1px solid #f0f0f4;
  background-color: #fff;
}
.profile-banner img {
  width: 980px;
  height: 200px;
}
.user-personal-detail {
  display: flex;
  flex-direction: column;
}
.username {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.parts-chip-and-social {
  display: flex;
  justify-content: space-between;
}
.visitWebsiteRoot {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}
.authroize-dealer-grid {
  display: flex;
  gap: 20px !important;
  flex-flow: wrap;
}
.speciliationFlex {
  display: flex;
  align-items: flex-start;
  gap: 0 7px !important;
}

/* 22/11 */
/* ---------------------------------------------
--------------------------------------------- */

.commingsoon-button {
  background: #f0f0f4 !important;
  border-radius: 4px !important;
  width: 320px !important;
  height: 50px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #9799b5 !important;
}

.heading-about-us {
  font-size: 32px;
  line-height: 56px;
  font-weight: 500;
  color: #13141b;
}
.about-text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
  height: 280px;
}
.about-img-box {
  width: 548px;
  height: 358px;
}
.about-hero {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  gap: 131px;
}
.section-industry {
  background-color: #f8f8fa;
}

.heading-industry {
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: #13141b;
  margin-bottom: 15px;
}

.industry-text-box {
  width: 500px;
  height: 255px;
}

.performance-list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  width: 380px;
  height: 240px;
  background: #ffffff;
  border: 1px solid #f0f0f4;
  border-radius: 4px;
}
.performance-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 30px 0 0 0;
}

.team-text-box {
  width: 580px;
  height: 356px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.team-img-box {
  width: 480px;
  height: 342px;
}
.section-support {
  background: #f8f8fa;
  padding: 90px 0;
}
.button-project {
  padding: 15px 30px;
  text-decoration: none;
  background-color: #f8f8fa;
  color: #6b6565;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.41);
}

.performance-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4a4d68;
  margin: 10px 170px;
}

.about-img {
  width: 548px;
  height: 358px;
}

.industry-list {
  display: flex;
  gap: 20px;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #36384c;
  margin-bottom: 10px;
}

.industry-list-item {
  list-style: none;
}

.heading-performance {
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
}
.industry-img-box {
  width: 580px;
  height: 340px;
}
.industry-img {
  width: 100%;
  height: auto;
}
.industry {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 100px;
}
.integrity-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #13141b;
  margin: 20px 0 6px 0;
}
.team {
  display: flex;
  margin: 0 auto;
  align-items: center;
  gap: 120px;
  flex-flow: wrap;
  flex-direction: row;
}
.support-img {
  background-color: #f8f8fa;
  box-shadow: 0px 16px 14px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.grid {
  display: grid;
  column-gap: 20px;
}

.grid-2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3-cols {
  grid-template-columns: repeat(3, 1fr);
}

/* .list {
  margin: 32px 0 0 0;
  padding: 32px 0 0 0;
  border-top: 1px solid #f0f0f4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 320px;
  height: 215px;
} */

.list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 17px 0;
}
.pricing-plan-free .list {
  height: 176px;
}
.pricing-plan-free {
  height: 488px !important;
}
.start-free-btn {
  width: 320px !important;
  height: 50px !important;
  border: 1px solid #9799b5 !important;
  border-radius: 4px !important;
  color: #4a4d68 !important;
  background-color: transparent !important;
}

.list-icon {
  height: 18px;
  width: 18px;
  color: #ffbe85;
}
.pricing-plan-free {
  gap: 30px 0;
  cursor: pointer;
}
.pricing-plan-super-saver {
  gap: 30px 0;
  cursor: pointer;
}

.margin {
  margin-bottom: 0 !important;
}

.section-prizing {
  /* margin: 90px 0; */
  /* padding: 90px 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* .section-hero {
  margin: 90px 0;
} */
.hero {
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1.5fr 1fr;
  align-items: center;
  gap: 120px;
}

.hero-img-box {
  width: 460px;
  height: 380px;
  text-align: center;
}

.hero-img {
  width: 460px;
  height: 380px;
}
.heading-primary {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: #13141b;
  margin: 0;
}

.seling-hero .hero-img-box {
  width: 490px;
  height: 360px;
}
.seling-hero .hero-img {
  width: 490px;
  height: 360px;
}
.seling-hero {
  display: flex;
  justify-content: space-between;
  gap: 103px;
  align-items: center;
}

.heading-secondary {
  font-size: 32px;
  line-height: 42px;
  font-weight: 500;
  text-align: center;
  color: #13141b;
}

.hero-heading-description {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #36384c;
}

.hero-description {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: #4a4d68;
}
.hero-text-box {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  padding-right: 23px;
}

.list-item {
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 13px;
  color: #4a4d68;
  margin: 0;
  line-height: 20px;
}

.subheading {
  display: block;
  font-size: 16;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 16px;
  letter-spacing: 0.75px;
}

.pricing-plan {
  border: 1px solid #f0f0f4;
  border-radius: 4px;
  padding: 30px;
  border: 1px solid #f0f0f4;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 380px;
  height: 527px;
  /* height: 566px; */
}

.pricing-plan:hover {
  box-shadow: 0px 16px 14px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.plan-name {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.75;
  margin-bottom: 20px;
  color: #36384c;
}

.plan-price span {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  color: #13141b;
}
.plan-price {
  font-weight: 500;
  color: #9799b5;
  margin-bottom: 8px;
}

.heading-text {
  text-align: center;
  line-height: 24px;
  margin: 10px 0 30px 0;
}

.verification-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  list-style: none;
}

.verification-list-item span {
  background: #f0f0f4;
  border-radius: 4px;
  padding: 10px 13px;
  display: flex;
  flex-direction: row;
}

.verification {
  display: flex;
  justify-content: center;
  margin: 0 20px;
}

.section-verification {
  /* padding: 90px 0; */
  background: #f8f8fa;
}

.section-cta {
  /* margin: 90px 0; */
}

.heading-cta {
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
}

.sign-up,
.wounded-warrior-project {
  display: flex;
  justify-content: center;
  /* align-content: center; */
  margin: 30px 0;
}

.button-sign-up {
  /* padding: 15px 30px; */
  text-decoration: none;
  background-color: #ff8c1a;
  color: #fff;
  width: 149px !important;
  height: 50px;
  border-radius: 4px;
}
.call-to-action {
  display: flex;
  gap: 95px;
  /* flex-wrap: wrap; */
  align-items: center;
}
.call-to-action-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 603px;
  height: 278px;
}
.cta-img-box {
  width: 480px;
  height: 338px;
}

.sign-up-free {
  margin: 30px 0;
}

.pricing {
  width: 100%;
  flex-flow: wrap;
  display: flex;
  gap: 20px;
}

.plan-name {
  margin: 0;
  padding-bottom: 17px;
}
.plan-price {
  margin: 0;
  padding-bottom: 5px;
}
.pricing-plan-saver {
  gap: 30px 0;
  height: 488px !important;
  cursor: pointer;
}
.heading-text {
  display: flex;
  gap: 0 15px;
  align-items: center;
  text-transform: capitalize;
  color: #36384c;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 24px;
  padding-bottom: 30px;
}
.heading-secondary {
  margin: 0;
  padding-bottom: 10px;
}
.buying-body {
  border-top: 1px solid #f0f0f4;
}
.seling-hero {
  padding: 0;
}
.seling-hero .heading-primary {
  padding-right: 72px;
}
.seling-hero .hero-text-box {
  padding-right: 0;
}
.verification-title .heading-secondary {
  padding-bottom: 20px;
}
.verification-title .performance-text {
  margin: 0 auto;
  max-width: 820px;
}
.verification-list {
  margin-top: 50px;
  margin-bottom: 0;
}
.verification-list-item span {
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.verification-list-item {
  display: flex;
  padding: 20px;
  gap: 10px;
  width: 380px;
  height: 90px;
  background: #ffffff;
  border: 1px solid #f0f0f4;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #36384c;
  align-items: center;
}
.sign-up {
  margin: 0;
  margin-top: 30px;
}
.heading-cta {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: #13141b;
}
.call-to-action-text h1 {
  margin: 0;
  padding-bottom: 10px;
}
.call-to-action-text .hero-heading-description {
  padding-bottom: 20px;
}
.sign-up-free {
  margin-top: 30px;
  margin-bottom: 0;
}
.cta-img-box {
  height: 380px;
}
.selling-page-body {
  border-top: 1px solid #f0f0f4;
}
.about-body {
  border-top: 1px solid #f0f0f4;
}
.heading-about-us {
  font-size: 45px;
  line-height: 56px;
  font-family: 'Lexend Deca';
  margin: 0;
}
.about-text-box {
  height: unset;
  gap: 20px 0;
}

.industry-list {
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
}
.industry-list li {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #36384c;
}
.heading-industry {
  margin: 0;
  padding-bottom: 15px;
}
.industry-text-box {
  height: unset;
}
.industry-text-box {
  display: flex;
  flex-direction: column;
  /* gap: 30px 0; */
}
.performance-list {
  padding: 0;
}
.heading-performance {
  margin: 0;
  font-family: 'Lexend Deca';
  color: #13141b;
  padding-bottom: 10px;
}
.performance-text {
  margin: 0 auto;
  max-width: 728px;
}
.performance-list-item {
  height: unset;
}
.team-text-box h1 {
  padding-bottom: 10px;
}
.team-text-box .hero-heading-description {
  padding-bottom: 20px;
}
.team-text-box .hero-description {
  padding-bottom: 20px;
}

.frequently-ask-question-inner .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0;
}
.frequently-ask-question-inner .css-67l5gl.Mui-expanded {
  margin: 0;
}
.profile-banner {
  background-color: #fff;
}
.pagination-btn {
  display: flex;
  justify-content: space-between;
}
.selling-page-pricing .pricing-plan-free .list {
  height: 215px;
}
.footer-main {
  /* position: absolute; */
  /* width: 100%; */
  /* bottom: 0; */
}
.everyOrderBtn {
  border: 1px solid #9799b5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #4a4d68;
  width: 240px;
  height: 50px;
  border-radius: 4px;
}
.visitWebsite {
  color: #4a4d68;
  border: 1px solid #9799b5;
  width: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  gap: 0 10px;
  text-decoration: none;
  border-radius: 4px;
  /* '& div': {
      justifyContent: 'space-between',
      width: '100%',
    }, */
}
a {
  text-decoration: none;
}
.buyerDetailRoot {
  display: flex;
  flex-direction: column;
  gap: 2px 0 !important;
  z-index: 9999;
}
.create-quote-request-list-card {
  z-index: 1;
}
.request-detail-left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.request-detail-body-inner p span a {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #4a4d68;
}
.section-cta .cta-img-box {
  height: 354px;
  width: 480px;
}
.section-cta .cta-img-box .hero-img {
  height: 354px;
  width: 480px;
}
.create-quote-request {
  background: #f8f8fa;
}
.create-quote-request-list-card {
  background-color: #fff;
}
.brands-title {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9799b5;
  margin-bottom: 10px;
  text-align: center;
}
.parts-search-sales-item-inner h3 {
  height: 76px;
}
.still-question {
  background: #f8f8fa;
  border-radius: 4px;
  padding: 40px;
  margin-top: 50px;
  text-align: 'center';
}
.still-question-title h3 {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #13141b;
}
.still-question-title p {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a4d68;
  margin-top: 5px;
}

.still-question-title p a {
  color: #36384c;

  font-weight: 700;
}
.still-question-title {
  text-align: center !important;
  max-width: 533px;
  margin: 0 auto;
  padding-top: 15px;
}
.still-question-inner img {
  margin: 0 auto;
  display: flex;
}
.quoterequest-inner {
  background: #f8f9f9;
}
.request-detail-message-inner p {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #da7010;
}

.request-detail-message-inner {
  border: 1px solid #ffeabf;
  border-radius: 4px;
  background: #fff7e6;
  height: 80px !important;
  display: flex;
  justify-content: center;
  align-items: center !important;
  margin-bottom: 30px;
}
.section-hero {
  background: #f8f8fa;
}
.become-seller-btn {
  width: 159px !important;
  height: 50px;
}
.pricing-plan-super-saver-height {
  height: 550px;
}
.pricing-plan-saver-height {
  height: 515px !important;
}
.dot {
  padding: 0 10px;
  color: #ffffff;
}
.textunderBtn {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #9799b5;
  padding-top: 10px;
  display: block;
}
/* .blueLinksBtn {
  background: transparent !important;
  height: unset !important;
  width: unset !important;
  padding: unset !important;
  min-height: unset !important;
  color: '';
  border-bottom: 1px solid !important;
  border-radius: unset !important;
  line-height: 1 !important;
  z-index: 99999 !important;


}
.blueLinksBtn .div span {
  line-height: unset;
}
.blueLinksBtn:hover {
  background: transparent;
} */
.card-left-img img {
  border: 1px solid #f0f0f4;
}
.trusted-supplier-item-inner img {
  width: 90px;
  margin: 0 auto;
}
/* .searchfield-root div:first-child {
  max-width: 580px;
  margin: 0 auto;
  width: 100%;
} */
.searchfield-root {
  max-width: 580px;
  margin: 0 auto;
  width: 100% !important;
  border-radius: 4px;
  background-color: #fff;
}
.searchfield-root input {
  height: 50px;
  max-width: 580px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 4px;
}
.searchfield-root {
  margin-top: 30px;
}
.industry-part-title h2 {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  color: #13141b;
}
.industry-part-title p {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4a4d68;
  padding-top: 10px;
}
.industry-part-title {
  text-align: center;
  padding-bottom: 40px;
}
.industry-part {
  background-image: url(../../../assets/images/part_quote_bg.png);
  background-size: cover;
}
.p-cardRoot {
  display: flex;
  gap: 0 10px !important;
  padding: 20px;
  background: #fff;
}
.p-cardLeft img {
  width: 58px;
  height: 58px;
  border: 1px solid #f0f0f4;
}
.p-cardRight {
  width: 90% !important;
}
.part-title {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #13141b;
}
.p-SearchField {
  display: flex;
  justify-content: space-between;
}
.p-SearchField .div {
  width: 40%;
}
.p-detail-page {
  border-top: 1px solid #f0f0f4;
}
.p-card-subflex {
  display: flex;
  justify-content: space-between !important;
}
.detailpage-title {
  display: flex;
  flex-direction: column;
  gap: 5px 0 !important;
}
.product-slider p {
  padding-top: 15px;
}

.searchResultDataRooot {
  position: relative;
}
.searchResultData {
  position: absolute;
  width: 100% !important;
  height: 382px !important;
  overflow-y: scroll;
  z-index: 999;
  box-shadow: 0px 24px 48px -12px rgba(21, 22, 30, 0.18);
  border-radius: 4px;
  border: 1px solid #f0f0f4;
  background-color: #fff;
}
.p-cardRoot {
  cursor: pointer;
  padding: 20px;
}
.empty-search {
  position: absolute;
  width: 100% !important;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0px 24px 48px -12px rgba(21, 22, 30, 0.18);
  border-radius: 4px;
  border: 1px solid #f0f0f4;
}
.empty-search p {
  color: #36384c;
  padding: 13px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  gap: 0 5px;
}

.searchResultData::-webkit-scrollbar {
  display: none;
}
.empty-search p a {
  cursor: pointer;
  color: #2680d9;
  text-decoration: underline;
}
.free-quote-link {
  cursor: pointer;
  color: #2680d9;
  text-decoration: underline;
}

.speciliationFlex {
  align-items: flex-start !important;
}
.breadCrubms-partdetail p {
  display: flex;
  gap: 0 3px;
}
.breadCrubms-partdetail div {
  cursor: pointer;
}
